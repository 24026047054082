import { ApiClient } from '@api'
import { FileFromResponse, IGridQuery, IGridResult } from '@api/models'
import { IApplicationStatus } from 'pages/application/model/IApplicationStatus'
import { ApplicationType } from 'shared'

export type SubmittedApplicationStatus =
    'All' |
    'New' |
    'Rejected' |
    'Cancelled' |
    'Handled' |
    'Granted' |
    'Paid'

export interface IGetDealerSubmittedApplicationsRequest extends IGridQuery {
  sellerAgentNo?: string | null
  status: number
  customer: string
  from: string
  to: string
}

export interface IGetMySubmittedApplicationsRequest extends IGridQuery {
  status: number
  customer: string
  from: string
  to: string
}

export interface ISubmittedApplicationOverview {
  caseNumber: string,
  referenceId: string
  dateReceived: string
  contractName: string
  customerName: string
  agreementName: string
  status: SubmittedApplicationStatus,
  salesPerson: string,
  vin: string,
  cancellable: boolean,
}

export interface ISubmittedApplication {
  id: number
  caseNumber: number
  agreementType: string
  agreementCode: number
  status: SubmittedApplicationStatus
  coBuyerFullName: string
  contractName: number
  customerEmail: string
  customerFullName: string
  dateReceived: string
  downPayment: number
  financedAmount: number
  guarantorName: string
  referenceId: string,
  numberOfTerms: number
  objectDescription: string
  objectPrice: number
  objectRegistrationNumber: string
  objectSerialNumber: string
  objectType: string
  objectYear: string
  residualValue: string
  serviceAgreement: string
  signatureTypeId: number | null,
  signatureTypeName: string | null,
  monthlyPayment: number
  interestRate: number
  telephoneNumber: string,
  type: ApplicationType,
  isDealerIntegratorCase: boolean,
}

export interface IGenerateContractResponse {
  externalReference: string
}

export interface IObjectDeliveredRequest {
  referenceId: string,
  vehicleRegistrationNumber: string,
  overrideRegistrationNumberCheck: boolean,
}

export interface IObjectDeliveredResponse {
  regNumberIsInvalidAccordingETL: boolean
}

export interface AvailableDocument {
  contractPageTypeId: number,
  contractPageTypeDescription: string,
  contractPageTypeGroup: number,
  contractPageTypeOrder: number,
}

export interface ISentMessage {
  date: string,
  subject: string,
}

export interface IGeneratePrivateContractRequest {
  referenceId: string,
  vehicleRegistrationNumber: string,
  vehicleIdentificationNumber: string,
  deliveryDate: string | undefined,
  signatureType: number
}

export interface IGenerateCorporateOrLeasingContractRequest {
  referenceId: string,
  vehicleRegistrationNumber: string,
  vehicleIdentificationNumber: string,
  deliveryDate: string | undefined,
}

export interface IGenerateContractUploadLinkResponse {
  url: string,
}

export interface IGetSubmittedApplicationXmlResponse {
  xml: string,
}

export class SubmittedApplicationApiClient {
  constructor(protected apiClient: ApiClient) { }

  public getDealerApplications = (dealerId: number, params: IGetDealerSubmittedApplicationsRequest, configuration?: { suppressError: boolean }) => {
    const url = `api/dealers/${dealerId}/submitted-applications`
    return this.apiClient.get<IGridResult<ISubmittedApplicationOverview>>(url, params, configuration)
  }

  public getMyApplications = (dealerId: number, params: IGetMySubmittedApplicationsRequest, configuration?: { suppressError: boolean }) => {
    const url = `api/dealers/${dealerId}/submitted-applications/my`
    return this.apiClient.get<IGridResult<ISubmittedApplicationOverview>>(url, params, configuration)
  }

  public getApplication = (dealerId: number, referenceId: string) => {
    const url = `api/dealers/${dealerId}/submitted-applications/${referenceId}`
    return this.apiClient.get<ISubmittedApplication>(url).then(x => x.data)
  }

  public getAvailableDocuments = (dealerId: number, referenceId: string) => {
    const url = `api/dealers/${dealerId}/submitted-applications/${referenceId}/available-documents`
    return this.apiClient.get<AvailableDocument[]>(url).then(x => x.data)
  }

  public downloadDocuments = (dealerId: number, referenceId: string, documentTypeIds: number[]) => {
    const url = `api/dealers/${dealerId}/submitted-applications/${referenceId}/download-documents`
    return this.apiClient.get(url, { documentTypeIds })
      .then(x => {
        if(!x.success){
          return
        }

        return new FileFromResponse(x.response!).download()
      })
  }

  public getDocumentUploadLink = (dealerId: number, referenceId: string) => {
    const url = `api/dealers/${dealerId}/submitted-applications/${referenceId}/document-upload-link`
    return this.apiClient.post<{ mainBuyerLink: string, coBuyerLink: string }>(url).then(x => x.data)
  }

  public getStatus = (dealerId: number, referenceId: string) => {
    const url = `api/dealers/${dealerId}/submitted-applications/${referenceId}/status`
    return this.apiClient.get<IApplicationStatus>(url).then(x => x.data)
  }

  public queueContractGeneration = async (dealerId: number, request: IGeneratePrivateContractRequest) => {
    const url = `api/dealers/${dealerId}/submitted-applications/queue-contract-generation`
    return this.apiClient.post<IGenerateContractResponse>(url, request)
  }

  public generateContract = async (dealerId: number, request: IGenerateCorporateOrLeasingContractRequest) => {
    const url = `api/dealers/${dealerId}/submitted-applications/generate-contract`
    return this.apiClient.post<IGenerateContractResponse>(url, request)
  }

  public objectDelivered = async (dealerId: number, request: IObjectDeliveredRequest) => {
    const url = `api/dealers/${dealerId}/submitted-applications/object-delivered`
    return this.apiClient.post<IObjectDeliveredResponse>(url, request)
  }

  public generateContractUploadLink = async (dealerId: number, referenceId: string) => {
    const url = `api/dealers/${dealerId}/submitted-applications/${referenceId}/generate-contract-upload-link`
    return this.apiClient.post<IGenerateContractUploadLinkResponse>(url)
  }

  public getSubmittedApplicationXml = (dealerId: number, referenceId: string) => {
    const url = `api/dealers/${dealerId}/submitted-applications/${referenceId}/get-submitted-application-xml`
    return this.apiClient.get(url)
    .then(x => {
      if(!x.success){
        return
      }
      
      return new FileFromResponse(x.response!).download()
    })
  }

}